import { RouteComponentProps } from '@reach/router';
import React from 'react';

import './DeleteAccount.css';

const DeleteAccountPage: React.FC<RouteComponentProps> = () => {
  return (
    <div className="delete-data">

      <article className="article">
        <header className="article-header">
          <h1 title="Deleting your Kuku account" className="article-title">
            Deleting your Kuku account

          </h1>
        </header>
        <section className="article-info">
          <div className="article-content">
            <div className="article-body"><h3><em><span>Temporarily hiding your profile&nbsp;</span></em></h3>
              <p><span>Search adn discovery is the part of the app where you get to check out potential matches. You can hide your profile so that you won’t be shown to anyone in the search.&nbsp;</span><span>To hide your profile: </span></p>
              <ol>
                <li><span>Open Kuku app </span></li>
                <li><span>Tap the profile icon </span></li>
                <li>Enable "Hide my profile"</li>
              </ol>
              <p><span>Even when the Profile is hidden, you can still view and message your matches.</span></p>
              <p><span>Note: Even when profile hidden, people you had already Liked may still be able to see your profile and Like you back; this means you might get new matches while you have Profile is hidden. </span></p>
              <h3><em><span>Permanently deleting your account</span></em></h3>
              <p><span>For security reasons, you’ll need to delete your Kuku account while signed in to the app. </span>
                <span>Keep in mind, when you delete your account you permanently lose matches, messages, and other data associated with it. </span><span>To delete your account:&nbsp;</span></p>
              <ol>
                <li>Sign in to the Kuku app</li>
                <li>Tap the "More" icon</li>
                <li>Go to Settings</li>
                <li>Scroll down and select Delete Account</li>
              </ol>
              <p><strong>Note:&nbsp;</strong>Deleting the Kuku app does not delete your account. </p>
            </div>
          </div>
        </section>
      </article>
    </div>
  );
}

export default DeleteAccountPage;

import React from 'react';
import { Link, RouteComponentProps } from '@reach/router';

import './Privacy.css';

const PrivacyPage: React.FC<RouteComponentProps> = () => {
  return (
    <div className="privacy">
      <h1>Privacy Policy</h1>
      <p>
        Welcome to the Privacy Policy ("Policy")! This explains how we
        collect, store, protect, and share your information, and with whom we
        share it. We suggest you read this in conjunction with our{' '}
        <Link to="/terms">Terms and Conditions of Use</Link>. Whilst you're
        enjoying the Kuku mobile application ("App"), we collect some
        information about you. In addition, you may choose to use the App to
        share information with other Kuku users, including your friends and
        contacts ("Users"). We may also need to share your information
        sometimes.
      </p>

      <p>
        Kuku is a global app, and your information will be sent to and used in
        the United States regardless of the country you reside in. This Policy
        explains how we protect your personal data when we transfer it
        overseas, so please read very carefully!
      </p>

      <ol>
        <li>
          <h3>Effective date</h3>
          <p>The Privacy Policy was last updated on: January 10, 2019.</p>
        </li>
        <li>
          <h3>COLLECTION OF INFORMATION.</h3>
          <h4>Registration Information</h4>
          <p>
            When you download the App and create an account ("Account"), we
            may collect certain information ("Registration Information") about
            you, such as:
          </p>
          <ul>
            <li>Name;</li>
            <li>Username;</li>
            <li>Email address;</li>
            <li>Mobile number;</li>
            <li>Gender identity;</li>
            <li>Date of birth;</li>
            <li>Sexual preference;</li>
            <li>Photographs;</li>
            <li>Location; and</li>
            <li>
              Login information, friend lists, page likes for social media
              accounts that you connect to your Kuku Account (this could
              include, for example, your Facebook account ("Social Media
              Accounts").
            </li>
          </ul>
          <p>
            Once you register, you will be able to review and change this
            information at any time by accessing your “Profile” page! You
            control your user profile and are able to correct or update (other
            than your email address, mobile number, date of birth, gender and
            location (which is automatically updated)) your information at any
            time by just logging in to Kuku.
          </p>
          <p>
            The information we collect helps to enhance the App and verify our
            Users. Registration Information such as your name and username may
            be visible to the public on your profile page.
          </p>
          <h4>Additional Information</h4>
          <p>
            We may ask you to provide your full name, address and email
            address. Such details may be shared with third parties for
            purposes relating to, for example, any of our loyalty programs,
            sending gifts or delivery of merchandise.
          </p>

          <p>
            We may also collect additional information in connection with your
            use of Kuku, for example if you use our electronic messaging
            funcionality.
          </p>

          <p>
            If you contact our Customer Support team, we will receive your
            email address, and may track your IP address as well as the
            information you send to us to help resolve your query. We will
            keep records of our communications with you, including any
            complaints that we receive from you about other Users (and from
            other Users about you).
          </p>
          <p>
            We recommend and encourage you (and all our members) to think
            carefully about the information you disclose about yourself. We
            also do not recommend that you put email addresses, URLs, instant
            messaging details, phone numbers, full names or addresses, credit
            card details, national identity numbers, drivers' licence details
            and other sensitive information on your profile which is open to
            abuse and misuse.
          </p>
          <p>
            Please be careful about posting sensitive details about yourself
            on your profile such as your religious denomination and health
            details. While you may voluntarily provide this information to us
            when you create your profile, including your sexual preferences,
            there is no requirement to do so. Please remember that photographs
            that you post on Kuku may reveal information about yourself as
            well. Where you do upload and choose to tell us sensitive
            information about yourself, you are explicitly consenting to our
            processing of your information and making this public to other
            users.
          </p>
          <p>
            When you post information about yourself or use the messaging
            function, the amount of personal information you share is at your
            own risk. If you post anything that is inconsistent with our Terms
            and Conditions of Use, we reserve the right to terminate your
            Account!
          </p>

          {/* <p>
          For safety and security and to ensure you have the best possible
          user experience, we require users to verify their accounts and might
          ask for your phone number. We want to make sure you are not a robot!
          And we also want to avoid fake Kuku accounts being created which
          can be used for malicious activities and cybercrime – they threaten
          the Kuku network and spoil things for everyone. We use a third
          party provider for account verification who will keep your phone
          number for up to 90 days for fraud identification purposes across a
          range of online platforms and services like Kuku’s.
        </p> */}

          {/* <p>
          If you decide to purchase any of our premium services, we will
          process your payment information and retain this securely for the
          prevention of fraud and for audit/tax purposes.
        </p> */}

          <h4>Data Storage</h4>
          <p>
            By using the App, you acknowledge that Kuku is a global app
            operating through servers located in a number of countries around
            the world, including the United States. If you live in a country
            with data protection laws, the storage of your personal data may
            not provide you with the same protections as you enjoy in your
            country of residence.
          </p>

          <h4>Geolocation Information</h4>
          <p>
            If you turn these features on, when you use your mobile, we will
            collect information about WiFi access points as well as other
            location information about your longitude and latitude and may
            save your device's coordinates to offer certain features to you.
            This information helps us identify your physical location and we
            may use it to personalize the App and make it easier for you to
            interact with other Users, by enabling the information to be
            displayed and shared with other members.
          </p>

          <h4>Log and Usage Data</h4>
          <p>
            We keep your personal information only as long as we need it for
            legitimate business purposes as permitted by applicable law. To
            protect the safety and security of our users on and off our
            services, we implement a safety retention window of three months
            following account deletion. During this period, account
            information will be retained although the account will of course
            not be visible on the services anymore.
          </p>
          <p>
            In practice, we delete or anonymize your information upon deletion
            of your account (following the safety retention window), unless:
          </p>
          <ol>
            <li>
              we must keep it to comply with applicable law (for instance,
              some “traffic data” is kept for one year to comply with
              statutory data retention obligations);
            </li>
            <li>
              we must keep it to evidence our compliance with applicable law
              (for instance, records of consents to our Terms, Privacy Policy
              and other similar consents are kept for five years);
            </li>
            <li>
              there is an outstanding issue, claim or dispute requiring us to
              keep the relevant information until it is resolved; or
            </li>
            <li>
              the information must be kept for our legitimate business
              interests, such as fraud prevention and enhancing users' safety
              and security. For example, information may need to be kept to
              prevent a user who was banned for unsafe behavior or security
              incidents from opening a new account.
            </li>
          </ol>
          <h4>Device Information</h4>
          <p>
            We may collect information about your device when you use the App
            including the unique device identifier, device model, operating
            system, and MAC address. In addition, if you permit us to do so,
            the App may access your device's address book solely in order to
            add someone to your contacts.
          </p>

          <h4>Links</h4>
          <p>
            We may keep track of how you interact with links available on
            Kuku including third party services and clients by redirecting
            clicks or through other means. We may share aggregate click
            statistics such as how many times a particular link was clicked
            on.
          </p>

          <h4>Cookies</h4>
          <p>
            Cookies are small data files that are transferred to your
            computer's hard disk and are basically a tool that stores
            information about website visits, recognises you and your
            preferences each time you visit Kuku, and ensures site
            functionality and enables us to provide the services our members
            request.
          </p>
          <p>
            The cookies we collect enable us to learn how people interact with
            Kuku, which in turn helps us make a better product for you!
            Cookies store information about your website visits and can
            recognise you and your preferences each time you visit the Kuku
            site. They help us to provide a better service to you!
          </p>
        </li>

        <li>
          <h3>USE OF INFORMATION.</h3>
          <p>
            Our main goal is to ensure your experience on Kuku is an enjoyable
            one. In order to deliver an enjoyable experience to you, we may
            use your Registration and other information to:
          </p>
          <ul>
            <li>offer you our services and features;</li>
            <li>
              contact you with information about the App (e.g., updates and
              new offerings);
            </li>
            <li>personalize the App and the content we deliver to you;</li>
            <li>
              conduct research and analytics about how you use and interact
              with the App;
            </li>
            <li>resolve disputes between you and other Users; and</li>
            <li>
              investigate fraud, protect our legal rights, and to enforce our
              Terms &amp; Conditions.
            </li>
          </ul>
        </li>

        <li>
          <h3>DISCLOSURE OF INFORMATION.</h3>
          <p>
            Our policy is to not disclose your Registration Information,
            except in the limited circumstances described here:
          </p>
          <p>
            <b>Your Consent</b> – If you consent, we may share or disclose
            your Registration Information, such as when you use a third party
            web client or application to access your Account.
          </p>
          <p>
            <b>Service Providers</b> - We engage certain trusted third parties
            to perform functions and provide services to us. We may share your
            Registration Information with these third parties, but only for
            the purposes of performing these functions and providing such
            services e.g. information technology companies (hardware and
            software) which provide services to support our products.
          </p>
          <p>
            <b>Moderators</b> – To monitor activity on the apps and approve
            content.
          </p>
          {/* <p><b>Payment Processing and Telecommunications Companies</b> – To facilitate payments for our premium services.</p> */}
          <p>
            <b>Law and Harm</b> - As we mentioned in the{' '}
            <Link to="/terms">Terms &amp; Conditions</Link>, we think it is
            very important that all Kuku users behave whilst using the App. We
            will cooperate with all third parties to enforce their
            intellectual property or other rights. We will also cooperate with
            law enforcement enquiries from within or outside your country of
            residence where we are required to by law or to protect the vital
            interests of a person. This may include preserving or disclosing
            any of your information, including your Registration Information,
            if we believe in good faith that it is necessary to comply with a
            law or regulation, or when we believe that disclosure is necessary
            to comply with a judicial proceeding, court order, or legal
            request; to protect the safety of any person; to address fraud,
            security or technical issues e.g. through anti-spam providers to
            protect the service from criminal activity or to protect our
            rights or property or those of third parties. In such cases we may
            raise or waive any legal objection or right available to us.
          </p>
          <p>
            <b>Business Transfers</b> -In the event that Kuku or any of our
            affiliates undergoes a business transition or change of ownership,
            such as a merger, acquisition by another company, re-organisation,
            or sale of all or a portion of its assets, or in the event of
            insolvency or administration, we may be required to disclose your
            personal data.
          </p>
          <p>
            <b>Aggregated Information</b> – We may share aggregated
            information with third parties that includes your personal data
            (but which doesn’t identify you directly) together with other
            information including log data for industry analysis and
            demographic profiling. You may opt-out of receiving marketing
            messages by using the opt-out mechanisms and links provided in
            each email.
          </p>
          <p>
            We ensure these parties must adhere to strict data protection and
            confidentiality provisions that are consistent with this Policy.
            Measures are taken to ensure that the data shared is
            non-attributable to the greatest extent possible.
          </p>
        </li>
        <li>
          <h3>WHAT OTHERS MAY SEE ABOUT YOU.</h3>
          <p>
            We think our Kuku users are awesome, and we want you to share how
            awesome you are with the world, so we have built certain features
            to enable this. Our App is designed to make it easier for you to
            connect with other Users and to interact with them. If you
            register or login to the App using your Facebook account, you are
            authorizing us to access certain Facebook account information,
            including information you make available via Facebook, your
            friends list, current location and those friends you have in
            common with other Kuku users. You also understand that your Kuku
            profile and other information you make available via the App,
            including information you provide directly or indirectly through
            Facebook (i.e., your Facebook photos, your name, age, approximate
            location, friends you have in common with other Kuku users and
            other profile information), may be viewed and shared by Kuku users
            with individuals who may or may not be Users or via third party
            applications in such Kuku users’ discretion. For example, a User
            may recommend you as a match to his or her Facebook friends by
            sharing your picture, regardless of whether such friend(s) is also
            a User of the App. You might share information like the photos and
            messages you send or upload and any metadata provided with those
            messages, along with any comments or information you provide in
            connection with your photos.
          </p>

          <p>
            When using Kuku, you should assume that <b>anything</b> you post
            or submit on the App may be publicly-viewable and accessible, both
            by Users of the App and non-Users of the App. We want our Kuku
            users to be careful about posting information that will eventually
            be made public.
          </p>
        </li>

        <li>
          <h3>MODIFYING YOUR REGISTRATION INFORMATION.</h3>
          <p>
            You may access or modify the Registration Information you provided
            at anytime or permanently delete your Account.
          </p>

          <p>
            When your Account is deactivated, we take reasonable efforts to
            make sure it is no longer viewable on the App. We are not
            responsible for any information, pictures, comments, or other
            content that is deleted from our systems resulting from the
            deactivation of your Account.
          </p>

          <p>
            To prevent abuse and/or misuse of Kuku by a User following
            termination or deletion of a profile/Account we shall retain such
            information as we deem in our sole discretion may be necessary to
            ensure that User does not open a new Account and profile in breach
            of our <Link to="/terms">Terms and Conditions of Use</Link> and to
            ensure compliance with all laws and regulations.
          </p>

          <p>
            <b>Warning</b>: Even after you remove information from your
            profile or delete your Account, copies of that information may
            still be viewable and/or accessed to the extent such information
            has been previously shared with others, or copied or stored by
            other Users or to the extent such information has been shared with
            search engines. We cannot control this, nor do we accept any
            liability for this. If you have given third party applications or
            websites access to your personal information they may retain such
            information to the extent permitted under their terms of service
            or privacy policies.
          </p>
        </li>

        <li>
          <h3>OUR POLICY TOWARDS AGE.</h3>
          <p>
            Although we want as many people as possible to enjoy our creation,
            you have to be at least 18 years old to use the App - sorry kids,
            we know Kuku is cool, but you'll have to come back when you're old
            enough!
          </p>

          <p>
            Kuku does not knowingly collect any information about or market to
            children, minors or anyone under the age of 18. If you are less
            than 18 years old, we request that you do not submit information
            to us. If we become aware that a child, minor or anyone under the
            age of 18 has registered with us and provided us with personal
            information, we will take steps to terminate that person's
            registration and delete their Registration Information from
            Kuku. If we do delete a profile because you violated our no
            children rules, we may retain your email and IP address to ensure
            that you do not try to get around our rules by creating a new
            profile.
          </p>
        </li>

        <li>
          <h3>CHANGES TO THIS POLICY.</h3>
          <p>
            As Kuku grows, we may revise this Privacy Policy from time to
            time. The most current version of the policy will govern our use
            of your information and will always be at{' '}
            <a href="/privacy">kuku.am/privacy</a>. If we make a change to
            this policy that, in our sole discretion, is material, we will
            notify you, for example, via an email to the email associated with
            your Account or by posting a notice within Kuku. By continuing to
            access or use the App after those changes become effective, you
            agree to be bound by the revised Privacy Policy.
          </p>
        </li>

        <li>
          <h3>SECURITY</h3>
          <p>
            Here at Kuku we pride ourselves on taking commercially reasonable
            security measures to help protect your information against loss,
            misuse and unauthorized access, or disclosure. We use reasonable
            security measures to safeguard the confidentiality of your
            personal information such as secured servers using firewalls.
          </p>

          <p>
            Unfortunately no website or Internet transmission is ever
            completely 100% secure and even we cannot guarantee that
            unauthorised access, hacking, data loss or other breaches will
            never occur, but here are some handy tips to help keep your data
            secure:
          </p>

          <ol>
            <li>
              Please make sure you log out of your Account after use as you
              never know who may stumble onto your Account!
            </li>
            <li>
              Please don't share your social media password with anyone else!
            </li>
            <li>Change your social media password periodically.</li>
          </ol>
          <p>
            If you ever think someone has had access to your social media
            password, please report it to the relevant social media platform
            and change your password immediately. We cannot guarantee the
            security of your personal data while it is being transmitted to
            our site and any transmission is at your own risk.
          </p>
          <p>
            <b>
              WE EXPRESSLY DISCLAIM ANY REPRESENTATION OR WARRANTY, WHETHER
              EXPRESS OR IMPLIED, WITH RESPECT TO ANY BREACHES OF SECURITY,
              DAMAGE TO YOUR DEVICE, OR ANY LOSS OR UNAUTHORIZED USE OF YOUR
              REGISTRATION INFORMATION OR OTHER DATA.
            </b>
          </p>
        </li>

        <li>
          <h3>YOUR CALIFORNIA PRIVACY RIGHTS.</h3>
          <p>
            California's "Shine the Light" law, Civil Code section 1798.83,
            requires certain businesses to respond to requests from California
            customers asking about the businesses' practices related to
            disclosing personal information to third parties for the third
            parties' direct marketing purposes. If you wish to find out about
            any rights you may have under California Civil Code section
            1798.83, you can write to us at titanoid@gmail.com
          </p>

          <p>
            From time to time we may disclose your contact information to
            third parties to allow them to market their products or services
            to you or for other marketing purposes. This may be information we
            received from you offline and online. If you want us to stop
            further sharing your email contact information with third parties
            (if we have your email contact information), you may notify us at
            titanoid@gmail.com. Please follow the instructions provided to you
            by third parties to unsubscribe from their messages. If you have
            opted-out as described above, and thereafter you chose to use a
            service or promotion that requires us to contact you or share your
            information with a third party, then your previous opt-out
            preferences will not apply to such service.
          </p>
          <p>
            In addition, under California law, operators of online services
            are required to disclose how they respond to "do not track"
            signals or other similar mechanisms that provide consumers the
            ability to exercise choice regarding the collection of personal
            information of a consumer over time and across third party online
            services, to the extent the operator engages in that collection.
            At this time, we do not track our Users' personal information over
            time and across third-party online services. This law also
            requires operators of online services to disclose whether third
            parties may collect personal information about their users' online
            activities over time and across different online services when the
            users use the operator's service. We do not knowingly permit third
            parties to collect personal information about an individual User's
            online activities over time and across different online services
            when using the App.
          </p>
        </li>

        <li>
          <h3>EU General Data Protection Regulation</h3>
          <p>
            Under UK and EU law, UK and EU users have the right to lodge a
            complaint with data protection regulators, and the Information
            Commissioners’ Office (ICO) is the UK’s lead regulator. You can
            find out how to raise a concern with the ICO by visiting their
            website at www.ico.org.uk. If you’re within the EU, you may also
            get in touch with your local Data Protection Regulator who may
            liaise with the ICO on your behalf.
          </p>
          <p>
            You have a number of rights under European Data Protection law if
            you are an EU citizen.
          </p>
          <ol>
            <li>
              Right to be informed: what personal data an organisation is
              processing and why (this notice).
            </li>
            <li>Right of access: you can request a copy of your data.</li>
            <li>
              Right of rectification: if the data held is inaccurate, you have
              the right to have it corrected.
            </li>
            <li>
              Right to erasure: you have the right to have your data deleted
              in certain circumstances.
            </li>
            <li>
              Right to restrict processing: in limited circumstances, you have
              the right to request that processing is stopped but the data
              retained.
            </li>
            <li>
              Right to data portability: you can request a copy of your data
              in a machine-readable form that can be transferred to another
              provider.
            </li>
            <li>
              Right to object: in certain circumstances (including where data
              is processed on he basis of legitimate interests or for the
              purposes of marketing) you may object to that processing.
            </li>
            <li>
              Rights related to automated decision making including profiling:
              there are several rights in this area where processing carried
              out on a solely automated basis results in a decision which has
              legal or significant effects for the individual. In these
              circumstances your rights include the right to ensure that there
              is human intervention in the decision-making process.
            </li>
          </ol>
          <p>
            Under EU and UK data protection laws, we are only permitted to use
            your data when we have a lawful basis to do so. The table below
            provides an overview of the legal bases that we rely on to use
            your data. Where the legal basis is consent, you can withdraw
            consent at any time. Where the legal basis is legitimate
            interests, you have a right to object to our use of your data. We
            explain in the relevant sections in this Policy how you can
            withdraw consent or opt-out of certain data uses (where
            applicable).
          </p>
          <table>
            <tbody>
              <tr>
                <th style={{width: '33%'}}>Purpose for which data is used</th>
                <th style={{width: '33%'}}>Data</th>
                <th>Legal basis</th>
              </tr>
              <tr>
                <td>
                  To provide you with the Kuku social networking service
                </td>
                <td>Name, email address, date of birth, location</td>
                <td>Contractual necessity</td>
              </tr>
              <tr>
                <td>
                  To facilitate networking opportunities on the Kuku site
                </td>
                <td>
                  Optional information that you choose to provide in your
                  profile, which may include information about your sexual
                  preferences, religion, ethnic background, photos etc.
                </td>
                <td>Consent</td>
              </tr>
              <tr>
                <td>
                  To verify your identity and prevent fraud and to ensure the
                  safety and security of Users
                </td>
                <td>Phone number</td>
                <td>
                  Legitimate interests – it is in our legitimate interests to
                  ensure that accounts are not set up fraudulently and to
                  safeguard Users of the site
                </td>
              </tr>
              {/* <tr>
                <td>To take payment for premium services</td>
                <td>Payment card details</td>
                <td>
                  Contractual necessity and legitimate interests – we have a
                  legitimate interest in receiving payment for our premium
                  services
                </td>
              </tr> */}
              <tr>
                <td>To serve targeted advertisements to Users of the App</td>
                <td>Demographic and location information</td>
                <td>
                  Legitimate interests – it is in our legitimate interests to
                  target advertisements so that users see relevant
                  advertisements and to allow us to generate income from
                  advertising revenue
                </td>
              </tr>
              <tr>
                <td>
                  To send you marketing information about our offers and
                  services
                </td>
                <td>Email address and mobile phone number</td>
                <td>
                  Legitimate interests – it is in our legitimate interests to
                  promote our products and services
                </td>
              </tr>
              <tr>
                <td>
                  To show “nearby” location information to you and other users
                  of the site
                </td>
                <td>
                  Wifi access points and location data when you use the App
                </td>
                <td>Consent</td>
              </tr>
              <tr>
                <td>
                  To carry out research and analysis to help us improve the
                  App
                </td>
                <td>
                  Log and usage data, including IP address, browser type,
                  referring domain, pages accessed, mobile carrier and search
                  terms
                </td>
                <td>
                  Legitimate interests – it is in our interests to analyse the
                  way in which Users are accessing and using our services so
                  that we can further develop the App and improve the service
                </td>
              </tr>
              <tr>
                <td>
                  To respond to correspondence and queries that you submit to
                  us
                </td>
                <td>Email address and IP address</td>
                <td>
                  Legitimate interests – it is in our legitimate interests to
                  respond to your queries to ensure that we provide a good
                  service to Users and troubleshoot problems
                </td>
              </tr>
            </tbody>
          </table>
        </li>
      </ol>
    </div>
  );
}

export default PrivacyPage;

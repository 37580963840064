import React from 'react';
import { Router } from "@reach/router"

import HomePage from './pages/Home/Home';
import DeleteAccount from './pages/DeleteAccount';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';

function App() {
  return (
    <Router>
      <HomePage path="/" />
      <DeleteAccount path="/delete-account" />
      <Terms path="/terms" />
      <Privacy path="/privacy" />
    </Router>
  );
}

export default App;

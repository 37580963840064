import React from 'react';
import { Link, RouteComponentProps } from '@reach/router';

import './Home.css';
import logo from './logo.png';

import screenthos1 from './screenshots/1.jpg';
import screenthos2 from './screenshots/2.jpg';
import screenthos3 from './screenshots/3.jpg';
import screenthos4 from './screenshots/4.jpg';
import screenthos5 from './screenshots/5.jpg';



const HomePage: React.FC<RouteComponentProps> = () => {
  return (
    <div className="home">
      <div className="logo-container">
        <img src={logo} alt="Kuku" className="logo" />
        <h3>Armenian Dating</h3>
        <ul className="screenshots">
          <li>
            <img src={screenthos1} alt="Kuku - Armenian Daing" />
          </li>
          <li>
            <img src={screenthos2} alt="Kuku - Armenian Daing" />
          </li>
          <li>
            <img src={screenthos3} alt="Kuku - Armenian Daing" />
          </li>
          <li>
            <img src={screenthos4} alt="Kuku - Armenian Daing" />
          </li>
          <li>
            <img src={screenthos5} alt="Kuku - Armenian Daing" />
          </li>
        </ul>

        <div className="app-store">
          <a className="app-store-link ios" href="https://itunes.apple.com/us/app/kuku-armenian-dating/id1447464528" target="_blank" rel="noreferrer" />
          <a className="app-store-link android" href="https://play.google.com/store/apps/details?id=am.kuku.dating" target="_blank" rel="noreferrer" />
        </div>
      </div>

      <div className="footer">
        <Link to="/privacy" className="link">
          Privacy Policy
        </Link>
        <Link to="/terms" className="link">
          Terms of Service
        </Link>
      </div>
    </div>
  );
}

export default HomePage;

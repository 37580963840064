import React from 'react';
import { Link, RouteComponentProps } from '@reach/router';

import './Terms.css';

const TermsPage: React.FC<RouteComponentProps> = () => {
  return (
    <div className="terms">
      <h1>Terms and Conditions</h1>
      <p>
        Welcome to Kuku's Terms and Conditions of Use (these "Terms"). This is
        a contract between you and Titanoid Inc and we want you to know yours
        and our rights before you use the Kuku application ("App"). Please
        take a few moments to read these Terms before enjoying the App,
        because once you access, view or use the App, you are going to be
        legally bound by these Terms (so probably best to read them first!).
      </p>

      <ol>
        <li>
          <h3>Effective date</h3>
          <p>The Terms were last updated on: January 10, 2019.</p>
        </li>
        <li>
          <h3>KUKU RULES</h3>
          <p>
            Before you can use our awesome App, you will need to register for
            an account ("Account"). In order to create an Account you must:
          </p>
          <ol>
            <li>be at least 18 years old; and</li>
            <li>
              be legally permitted to use the App by the laws of your home
              country.
            </li>
          </ol>
          <p>
            You can create an Account by using your Facebook login details. If
            you create an Account using your Facebook login details, you
            authorize us to access, display and use certain information from
            your Facebook account (e.g. profile pictures, relationship status,
            location and information about Facebook friends). For more
            information about what information we use and how we use it,
            please check out our <Link to="/privacy">Privacy Policy</Link>.
          </p>
          <p>
            You'll have great fun on Kuku, but if you feel the need to leave,
            you can delete your Account at any time by going to the 'Settings'
            page when you are logged in and clicking on the 'Delete account'
            link. Your Account will be deleted immediately but it may take a
            little while for Your Content to be completely removed from the
            App. Titanoid Inc reserve the right at our sole discretion to
            terminate or suspend any Account, or make use of any operational,
            technological, legal or other means available to enforce the Terms
            (including without limitation blocking specific IP addresses), at
            any time without liability and without the need to give you prior
            notice. You may not access, tamper with, or use non-public areas
            of the App or our systems. Certain portions of the App may not be
            accessible if you have not registered for an Account.
          </p>
        </li>
        <li>
          <h3>TYPES OF CONTENT</h3>
          <p>
            There are three types of content that you will be able to access
            on the App:
          </p>
          <ol>
            <li>content that you upload and provide ("Your Content");</li>
            <li>content that you upload and provide ("Your Content");</li>
            <li>content that Titanoid Inc provide ("Our Content").</li>
          </ol>
          <h4>There is certain content we can't allow on Kuku</h4>
          <p>
            We want our users to be able express themselves as much as
            possible and post all sorts of things on Kuku, but we have to
            impose restrictions on certain content which:
          </p>
          <ul>
            <li>
              contains language or imagery which could be deemed offensive or
              is likely to harass, upset, embarrass, alarm or annoy any other
              person;
            </li>
            <li>
              is obscene, pornographic, violent or otherwise may offend human
              dignity;
            </li>
            <li>
              is abusive, insulting or threatening, discriminatory or which
              promotes or encourages racism, sexism, hatred or bigotry;
            </li>
            <li>
              encourages any illegal activity including, without limitation,
              terrorism, inciting racial hatred or the submission of which in
              itself constitutes committing a criminal offence;
            </li>
            <li>is defamatory or libellous;</li>
            <li>
              relates to commercial activities (including, without limitation,
              sales, competitions and advertising, links to other websites or
              premium line telephone numbers);
            </li>
            <li>involves the transmission of "junk" mail or "spam";</li>
            <li>
              contains any spy ware, adware, viruses, corrupt files, worm
              programmes or other malicious code designed to interrupt, damage
              or limit the functionality of or disrupt any software, hardware,
              telecommunications, networks, servers or other equipment, Trojan
              horse or any other material designed to damage, interfere with,
              wrongly intercept or expropriate any data or personal
              information whether from Kuku or otherwise;
            </li>
            <li>
              itself, or the posting of which, infringes any third party's
              rights (including, without limitation, intellectual property
              rights and privacy rights);
            </li>
            <li>
              shows another person which was created or distributed without
              that person’s consent.
            </li>
          </ul>
          <h4>
            Kuku operates a zero-tolerance policy for this kind of content.
            Your account will be terminated.
          </h4>

          <h4>Your Content</h4>
          <p>
            As Your Content is unique, you are responsible and liable for Your
            Content and will indemnify, defend, release, and hold us harmless
            from any claims made in connection with Your Content. Sorry that
            was a bit of a mouthful, but you are what you post!
          </p>
          <p>
            You may not display any personal contact or banking information on
            your individual profile page whether in relation to you or any
            other person (for example, names, home addresses or postcodes,
            telephone numbers, email addresses, URLs, credit/debit card or
            other banking details). If you do choose to reveal any personal
            information about yourself to other users, whether via email or
            otherwise, it is at your own risk. We encourage you to use the
            same caution in disclosing details about yourself to third parties
            online as you would under any other circumstances.
          </p>
          <p>
            As Kuku is a public community, Your Content will be visible to
            other users of the App all around the world instantly - so make
            sure you are comfortable sharing Your Content before you post. As
            such, you agree that Your Content may be viewed by other users and
            any person visiting, participating in or who is sent a link to the
            App (e.g. individuals who receive a link to a user’s profile or
            shared content from other Kuku Users). By uploading Your Content
            on Kuku, you represent and warrant to us that you have all
            necessary rights and licences to do so, and automatically grant us
            a non-exclusive, royalty free, perpetual, worldwide licence to use
            Your Content in any way (including, without limitation, editing,
            copying, modifying, adapting, translating, reformatting, creative
            derivative works from, incorporating into other works,
            advertising, distributing and otherwise making available to the
            general publish such Content, whether in whole or in part and in
            any format or medium currently known or developed in the future).
          </p>
          <p>
            We may assign and/or sub-licence the above licence to our
            affiliates and successors without any further approval by you.
          </p>
          <p>
            We have the right to remove, edit, limit or block access to any of
            Your Content at any time, and we have no obligation to display or
            review Your Content.
          </p>

          <h4>Member Content</h4>
          <p>
            Other members of Kuku will also share content via the App. Member
            Content belongs to the user who posted the content and is stored
            on our servers and displayed via the App at the direction of the
            user providing the Member Content.
          </p>
          <p>
            You do not have any rights in relation to other users' Member
            Content, and you may only use other Kuku users' personal
            information to the extent that your use of it matches Kuku's
            purpose operation. You may not use other users' information for
            commercial purposes, to spam, to harass, or to make unlawful
            threats. We reserve the right to terminate your Account if you
            misuse other users' information.
          </p>
          <p>
            Member Content is subject to the terms and conditions of Sections
            512(c) and/or 512(d) of the Digital Millennium Copyright Act 1998.
            If you have a complaint about Member Content, please see the
            Digital Millennium Copyright Act section below for more
            information.
          </p>

          <h4>Our Content</h4>
          <p>
            You may be wondering what happens to the rest of the Content on
            Kuku. Well, it belongs to us! Any other text, content, graphics,
            user interfaces, trademarks, logos, sounds, artwork, and other
            intellectual property appearing on Kuku are owned, controlled or
            licensed by us and are protected by copyright, trademark and other
            intellectual property law rights. All right, title and interest in
            and to Our Content remains with us at all times.
          </p>
          <p>
            We grant you a non-exclusive, limited, personal, non-transferable,
            revocable, license to access and use Our Content, without the
            right to sublicense, under the following conditions:
          </p>
          <ol>
            <li>
              you shall not use, sell, modify, or distribute Our Content
              except as permitted by the functionality of the App;
            </li>
            <li>
              you shall not use our name in metatags, keywords and/or hidden
              text;
            </li>
            <li>
              you shall not create derivative works from Our Content or
              commercially exploit Our Content, in whole or in part, in any
              way; and
            </li>
            <li>you shall use Our Content for lawful purposes only.</li>
          </ol>
          <span>We reserve all other rights.</span>
        </li>

        <li>
          <h3>RESTRICTIONS ON THE APP</h3>
          <p>You agree to:</p>
          <ul>
            <li>
              comply with all applicable laws, including without limitation,
              privacy laws, intellectual property laws, anti-spam laws, equal
              opportunity laws and regulatory requirements;
            </li>
            <li>use your real name on your profile;</li>
            <li>use the services in a professional manner.</li>
          </ul>
          <p>You agree that you will not:</p>
          <ul>
            <li>
              act in an unlawful or unprofessional manner including being
              dishonest, abusive or discriminatory;
            </li>
            <li>
              misrepresent your identity, your current or previous positions,
              qualifications or affiliations with a person or entity;
            </li>
            <li>
              disclose information that you do not have the consent to
              disclose;
            </li>
            <li>
              create or operate a pyramid scheme, fraud or other similar
              practice.
            </li>
          </ul>
          <p>
            We don't like users misbehaving in the Kuku community – users
            should not do bad things to other users. Therefore, you can report
            any abuse or complain about Member Content by contacting us,
            outlining the abuse and/or complaint. You can also report a user
            directly from a profile or in chat by clicking the 'Report' link.
          </p>
          <p>
            Also, we don't appreciate users doing bad things to Kuku - we've
            worked hard on our creation, so scraping or replicating any part
            of the App without our prior consent is expressly prohibited. This
            includes by any means (automated or otherwise) other than through
            our currently available, published interfaces - unless you have
            been specifically allowed to do so in a separate agreement with
            us.
          </p>
        </li>
        <li>
          <h3>PRIVACY</h3>
          <p>
            For information about how Titanoid Inc collects, uses, and shares
            your personal data, please check out our:{' '}
            <Link to="/privacy">Privacy Policy</Link> – this is important
            stuff, and makes for great bedtime reading! By using Kuku, you
            agree that we can use such data in accordance with our{' '}
            <Link to="/privacy">Privacy Policy</Link>.
          </p>
        </li>

        <li>
          <h3>PUSH NOTIFICATIONS; LOCATION-BASED FEATURES</h3>
          <p>
            We may provide you with emails, text messages, push notifications,
            alerts and other messages related to the App and/or the Kuku
            services, such as enhancements, offers, products, events, and
            other promotions. After downloading the App, you will be asked to
            accept or deny push notifications/alerts. If you deny, you will
            not receive any push notifications/alerts. If you accept, push
            notifications/alerts will be automatically sent to you. If you no
            longer wish to receive push notifications/alerts from the App, you
            may opt out by changing your notification settings on your mobile
            device. With respect to other types of messaging or
            communications, such as emails, text messages, etc., you can
            unsubscribe or opt out in the setting sections of the App.
          </p>
        </li>

        <li>
          <h3>DISCLAIMER</h3>
          <p>
            THE APP, SITE, OUR CONTENT, AND MEMBER CONTENT ARE ALL PROVIDED TO
            YOU "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTY OF ANY KIND,
            EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, FITNESS
            FOR A PARTICULAR PURPOSE, TITLE, OR NON-INFRINGEMENT.
          </p>
          <p>
            SHOULD APPLICABLE LAW NOT PERMIT THE FOREGOING EXCLUSION OF
            EXPRESS OR IMPLIED WARRANTIES, THEN WE GRANT THE MINIMUM EXPRESS
            OR IMPLIED WARRANTY REQUIRED BY APPLICABLE LAW. NO ADVICE OR
            INFORMATION, WHETHER ORAL OR WRITTEN, SHALL CREATE ANY WARRANTY,
            REPRESENTATION OR GUARANTEE NOT EXPRESSLY STATED IN THIS SECTION.
          </p>
          <p>
            ADDITIONALLY, WE DO NOT MAKE ANY WARRANTIES THAT THE APP OR SITE
            WILL BE UNINTERRUPTED, SECURE OR ERROR FREE OR THAT YOUR USE OF
            THE APP OR SITE WILL MEET YOUR EXPECTATIONS, OR THAT THE APP,
            SITE, OUR CONTENT, ANY MEMBER CONTENT, OR ANY PORTION THEREOF, IS
            CORRECT, ACCURATE, OR RELIABLE. YOUR USE OF THE APP OR SITE IS AT
            YOUR OWN RISK. YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS
            WITH OTHER MEMBERS. TITANOID INC IS NOT RESPONSIBLE FOR THE
            CONDUCT OF ANY USER. KUKU DOES NOT CONDUCT CRIMINAL BACKGROUND
            CHECKS ON ITS MEMBERS.
          </p>
          <p>
            NEITHER US NOR ANY OWNER WILL BE LIABLE FOR ANY DAMAGES, DIRECT,
            INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, OR PUNITIVE,
            INCLUDING, WITHOUT LIMITATION, LOSS OF DATA, INCOME, PROFIT OR
            GOODWILL, LOSS OF OR DAMAGE TO PROPERTY AND CLAIMS OF THIRD
            PARTIES ARISING OUT OF YOUR ACCESS TO OR USE OF THE APP, SITE, OUR
            CONTENT, OR ANY MEMBER CONTENT, HOWEVER CAUSED, WHETHER BASED ON
            BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), PROPRIETARY
            RIGHTS INFRINGEMENT, PRODUCT LIABILITY OR OTHERWISE.
          </p>
          <p>
            THE FOREGOING SHALL APPLY EVEN IF WE WERE ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES. IF YOU BECOME DISSATISFIED IN ANY WAY
            WITH THE APP OR SITE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO STOP
            YOUR USE OF THE APP AND SITE.
          </p>
          <p>
            YOU HEREBY WAIVE ANY AND ALL CLAIMS ARISING OUT OF YOUR USE OF THE
            APP OR SITE. BECAUSE SOME STATES DO NOT ALLOW THE DISCLAIMER OF
            IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN TYPES
            OF DAMAGES, THESE PROVISIONS MAY NOT APPLY TO YOU. IF ANY PORTION
            OF THIS LIMITATION ON LIABILITY IS FOUND TO BE INVALID OR
            UNENFORCEABLE FOR ANY REASON, THEN OUR AGGREGATE LIABILITY SHALL
            NOT EXCEED ONE HUNDRED DOLLARS ($100).
          </p>
          <p>
            THE LIMITATION OF LIABILITY HEREIN IS A FUNDAMENTAL ELEMENT OF THE
            BASIS OF THE BARGAIN AND REFLECTS A FAIR ALLOCATION OF RISK. THE
            APP AND SITE WOULD NOT BE PROVIDED WITHOUT SUCH LIMITATIONS AND
            YOU AGREE THAT THE LIMITATIONS AND EXCLUSIONS OF LIABILITY,
            DISCLAIMERS AND EXCLUSIVE REMEDIES SPECIFIED HEREIN WILL SURVIVE
            EVEN IF FOUND TO HAVE FAILED IN THEIR ESSENTIAL PURPOSE.
          </p>
        </li>
        <li>
          <h3>DIGITAL MILLENNIUM COPYRIGHT ACT</h3>
          <p>
            Titanoid Inc has adopted the following policy towards copyright
            infringement in accordance with the Digital Millennium Copyright
            Act (the "DMCA"). If you believe any Member Content or Our Content
            infringes upon your intellectual property rights, please submit a
            notification alleging such infringement ("DMCA Takedown Notice")
            including the following:
          </p>
          <ol>
            <li>
              A physical or electronic signature of a person authorized to act
              on behalf of the owner of an exclusive right that is allegedly
              infringed;
            </li>
            <li>
              Identification of the copyrighted work claimed to have been
              infringed, or, if multiple copyrighted works at a single online
              site are covered by a single notification, a representative list
              of such works;
            </li>
            <li>
              Identification of the material claimed to be infringing or to be
              the subject of infringing activity and that is to be removed or
              access disabled and information reasonably sufficient to permit
              the service provider to locate the material;
            </li>
            <li>
              Information reasonably sufficient to permit the service provider
              to contact you, such as an address, telephone number, and, if
              available, an electronic mail;
            </li>
            <li>
              A statement that you have a good faith belief that use of the
              material in the manner complained of is not authorized by the
              copyright owner, its agent, or the law; and
            </li>
            <li>
              A statement that, under penalty of perjury, the information in
              the notification is accurate and you are authorized to act on
              behalf of the owner of the exclusive right that is allegedly
              infringed.
            </li>
          </ol>
        </li>
        <li>
              <h3>MISCELLANEOUS</h3>
              <p>
                As Kuku grows, we might have to make changes to these Terms so
                we reserve the right to modify, amend or change the Terms at
                any time (a "Change"). If we do this then the Changes will be
                posted on this page and we will indicate the Effective Date of
                the updates at the top of the Terms. In certain
                circumstances, we may send an email to you notifying you of a
                Change. You should regularly check this page for notice of any
                Changes – we want our users to be as informed as possible.
              </p>
              <p>
                Your continued use of Kuku following any Change constitutes
                your acceptance of the Change and you will be legally bound by
                the new updated Terms. If you do not accept any Changes to the
                Terms, you should stop using Kuku immediately.
              </p>
            </li>
      </ol>
    </div>
  );
}

export default TermsPage;
